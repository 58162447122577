<template>
    <!-- 首页 -->
    <div class="Home">
        <div class="swiper">
            <img :src="imgsList[imgsIndex].url" />
            <button @click="prev" class="prev"></button>
            <button @click="next" class="next"></button>
        </div>
        <div class="header">
            <Head :add="routeAdd"></Head>
        </div>
        <div class="main">
            <div class="title">
                <p>互联网+全域</p>
                <p>智慧医养建设运营服务提供商</p>
            </div>
            <div
                class="introduce"
            >专注于智慧健康产业，提供智慧医疗、互联网+医疗健康自主研发的相关核心产品和整体解决方案，为居民、医疗机构、卫健委、疾控中心和养老机构提供智慧信息技术咨询、设计、建设和运维服务。</div>
            <div class="toEnter" @click="toEnter">城银股份 ></div>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
export default {
    components: {
        Head,
    },
    data() {
        return {
            routeAdd: "",
            imgsIndex: 0,
            imgsList: [
                {
                    url: require("../assets/images/background_1.png"),
                },
                {
                    url: require("../assets/images/background_2.png"),
                },
                {
                    url: require("../assets/images/background_3.png"),
                },
            ],
        };
    },
    created() {
        this.routeAdd = this.$route.name;
        setInterval(() => {
            this.next();
        }, 5000);
    },
    methods: {
        //跳转
        toEnter() {
            window.location.href = "http://www.cy-coo.com";
        },
        //下一张
        next() {
            if (this.imgsIndex < 2) {
                this.imgsIndex++;
            } else {
                this.imgsIndex = 0;
            }
        },
        //上一张
        prev() {
            if (this.imgsIndex > 0) {
                this.imgsIndex--;
            } else {
                this.imgsIndex = 2;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.Home {
    height: 100vh;
    position: relative;
    .swiper {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        img {
            float: left;
        }
        .prev {
            transform: rotate(180deg);
            width: 1.38rem;
            height: 2.63rem;
            position: absolute;
            top: calc(50% - 1.31rem);
            left: 2.63rem;
            background: url("../assets/fonts/next.png");
            opacity: 0.2;
            z-index: 99;
        }
        .next {
            width: 1.38rem;
            height: 2.63rem;
            position: absolute;
            top: calc(50% - 1.31rem);
            right: 2.63rem;
            background: url("../assets/fonts/next.png");
            opacity: 0.2;
            z-index: 99;
        }
        .prev:hover,
        .next:hover {
            opacity: 0.6;
        }
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6.25rem;
        z-index: 10000;
    }
    .main {
        position: absolute;
        top: 6.25rem;
        left: 0;
        width: 100%;
        height: calc(100vh - 6.25rem);
        color: #fff;
    }
    .title {
        position: absolute;
        top: 14.63rem;
        left: 22.31rem;
        width: 32.5rem;
        height: 6.25rem;
        font-size: 40px;
    }
    .introduce {
        position: absolute;
        top: 24.63rem;
        left: 22.56rem;
        width: 48.31rem;
        height: 4.19rem;
        font-size: 18px;
    }
    .toEnter {
        position: absolute;
        top: 31.75rem;
        left: 22.5rem;
        width: 12.5rem;
        height: 3.5rem;
        border: solid 1px #fff;
        font-size: 18px;
        text-align: center;
        line-height: 3.5rem;
        user-select: none;
    }
}
</style>
